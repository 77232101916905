"use client";
import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./tooltip";
import { LucideProps } from "lucide-react";

const buttonVariants = cva(
  "inline-flex overflow-hidden items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300",
  {
    variants: {
      variant: {
        default:
          "bg-slate-900 text-slate-50 hover:bg-slate-900/90 dark:bg-slate-50 dark:text-slate-900 dark:hover:bg-slate-50/90",
        green:
          "bg-green-600 text-slate-50 hover:bg-green-600/90 dark:bg-primary dark:text-slate-900 dark:hover:bg-primary/90",
        blue: "bg-blue-400 text-slate-50 hover:bg-blue-400/90 dark:blue-700 dark:text-slate-900 dark:hover:blue-700/90",
        cyan: "bg-cyan-600 text-slate-50 hover:bg-cyan-600/90 dark:bg-cyan-500 dark:text-slate-900 dark:hover:bg-cyan-500/90",
        orange:
          "bg-orange-600 text-slate-50 hover:bg-orange-600/90 dark:bg-[#f69528] dark:text-slate-900 dark:hover:bg-[#f69528]/90",
        destructive:
          "bg-red-500 text-slate-50 hover:bg-red-500/90 dark:bg-red-900 dark:text-slate-50 dark:hover:bg-red-900/90",
        outline:
          "border border-slate-200 bg-white hover:bg-slate-100 hover:text-slate-900 dark:border-slate-800 dark:bg-slate-950 dark:hover:bg-slate-800 dark:hover:text-slate-50",
        gradient:
          "bg-white hover:bg-slate-100 hover:text-slate-900 dark:bg-slate-950 dark:hover:bg-slate-800 dark:hover:text-slate-50",
        secondary:
          "bg-slate-100 text-slate-900 hover:bg-slate-100/80 dark:bg-slate-800 dark:text-slate-50 dark:hover:bg-slate-800/80",
        card: "bg-card text-slate-900 hover:bg-card/80 dark:bg-card dark:text-slate-50 dark:hover:bg-card/80",
        ghost:
          "hover:bg-slate-100 hover:text-slate-900 dark:hover:bg-slate-800 dark:hover:text-slate-50",
        link: "text-slate-900 underline-offset-4 hover:underline dark:text-slate-50",
      },
      size: {
        default: "h-10 px-4 py-2",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        icon: "h-10 w-10",
        gradient_lg: "px-6 w-full py-4 text-2xl",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };

export interface ButtonIconProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  Icon: React.FC<LucideProps>;
  onClick?: () => void;
  tooltip?: string;
  outline?: boolean;
  large?: boolean;
  className?: string;
  iconClassName?: string;
  asChild?: boolean;
}

export const ButtonIcon = React.forwardRef<HTMLButtonElement, ButtonIconProps>(
  function BI(
    {
      Icon,
      onClick,
      tooltip,
      outline = true,
      large = false,
      className,
      iconClassName,
    },
    ref
  ) {
    if (tooltip)
      return (
        <TooltipProvider>
          <Tooltip delayDuration={350}>
            <TooltipTrigger asChild>
              <Button
                variant={outline ? "outline" : "ghost"}
                size="icon"
                onClick={onClick}
                className={className}
                ref={ref}
              >
                <Icon
                  className={`${
                    large ? "h-5 w-5" : "h-4 w-4"
                  } ${iconClassName}`}
                />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>{tooltip}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      );
    return (
      <Button
        variant={outline ? "outline" : "ghost"}
        size="icon"
        onClick={onClick}
        className={className}
        ref={ref}
      >
        <Icon className={`${large ? "h-5 w-5" : "h-4 w-4"} ${iconClassName}`} />
      </Button>
    );
  }
);

export function GradientOutlineButton({
  className,
  children,
  ...rest
}: {
  className?: string;
  children?: React.ReactNode;
} & ButtonProps) {
  return (
    <div
      className={`rounded-md bg-gradient-to-r px-[1px] py-[1px] ${className}`}
    >
      <Button variant="gradient" className="w-full" {...rest}>
        {children}
      </Button>
    </div>
  );
}
export function GradientOutlineButtonLarge({
  className,
  children,
  ...rest
}: {
  className?: string;
  children?: React.ReactNode;
} & ButtonProps) {
  return (
    <div
      className={`rounded-md bg-gradient-to-r px-[1px] py-[1px] ${className}`}
    >
      <Button
        variant="gradient"
        className="w-full"
        size="gradient_lg"
        {...rest}
      >
        {children}
      </Button>
    </div>
  );
}
