import { TypographyH1, TypographyLead } from "@/components/typography";
import { DataTable } from "./data-table";
import { columns } from "./columns";
import { useState } from "react";

import { useMatches } from "@/hooks/useMatches";
import { SEASON } from "@/constants/general";
import { Match } from "@/types";
import { bertSack } from "@/App";
import { MatchDialog } from "./match-dialog/dialog";

const leadingText = {
  preseason: `The Autumn/Winter league is almost underway! Prepare yourself for the inaugural season of Bértanque - you won't want to miss it!`,
  start:
    // "The Winter Season is finally underway! The inaugural season of Bértanque is shaping up to be the most exciting sporting event ever!",
    "The Winter Season is finally underway! The pre-season of Bértanque is shaping up to be suprisingly exciting; spectators and competitors alike unanimously agree - Season 1 is going to be epic!",
};

const LEADING: keyof typeof leadingText = "start";

const Schedule = () => {
  const [matches, loading, error] = useMatches(SEASON);

  const [selectedMatch, setSelectedMatch] = useState<Match>();
  const [matchDialogOpen, setMatchDialogOpen] = useState(false);

  const onRowClick = (data: Match) => {
    setSelectedMatch(data);
    setMatchDialogOpen(true);
  };

  return (
    <div className="w-full overflow-x-hidden fade-in">
      <div className="flex flex-col justify-between md:flex-row md:items-center md:gap-5">
        <div className="flex w-full items-center justify-between md:flex-col md:items-start md:justify-normal">
          <TypographyH1>Schedule</TypographyH1>
          <TypographyLead className="my-2 hidden max-w-4xl md:block">
            {leadingText[LEADING]}
          </TypographyLead>
          <img src={bertSack} alt="pertanque" className="max-h-12 md:hidden" />
        </div>
        <TypographyLead className="my-2 max-w-4xl md:hidden">
          {leadingText[LEADING]}
        </TypographyLead>
        <img
          src={bertSack}
          alt="pertanque"
          className="hidden max-h-40 md:block"
        />
      </div>

      {error ? (
        <>Error...</>
      ) : loading ? (
        <></>
      ) : (
        <div className="fade-in">
          <DataTable columns={columns} data={matches} onRowClick={onRowClick} />
        </div>
      )}
      {selectedMatch && (
        <MatchDialog
          open={matchDialogOpen}
          handleClose={() => setMatchDialogOpen(false)}
          match={selectedMatch}
        />
      )}
    </div>
  );
};

export default Schedule;
