import { petanqueBoi } from "@/App";
import {
  HeaderWithLead,
  TypographyH1,
  TypographyLead,
} from "@/components/typography";
import { GradientOutlineButtonLarge } from "@/components/ui/button";

const Home = () => {
  return (
    <div className="mt-20 flex h-full w-full flex-col items-center justify-center space-y-5 p-5 fade-in">
      <img src={petanqueBoi} alt="pertanque" className="max-h-40" />
      <TypographyH1>Bértanque!!</TypographyH1>
      <TypographyLead className="my-2 max-w-xl text-center">
        Get ready for the most exciting Pétanque competition on Planet Earth!
      </TypographyLead>
    </div>
  );
};

export default Home;
