import { bertBaseball } from "@/App";
import {
  HeaderWithLead,
  TypographyH1,
  TypographyLead,
} from "@/components/typography";
import { Button, GradientOutlineButtonLarge } from "@/components/ui/button";
import { weekHighlights } from "./content";
import { Play, PlayCircle } from "lucide-react";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { generateMannyQuote } from "@/constants/general";
import { useState } from "react";

export const WeekHighlights = ({
  week,
}: {
  week: (typeof weekHighlights)[number];
}) => {
  const [carouselOpen, setCarouselOpen] = useState(false);
  const [initialIndex, setInitialIndex] = useState(0);
  const [mannyQuote, setMannyQuote] = useState(generateMannyQuote());

  const handleClickItem = (index: number) => {
    setMannyQuote(generateMannyQuote());
    setInitialIndex(index);
    setCarouselOpen(true);
  };
  return (
    <div className="space-y-4 rounded-md border bg-card p-5">
      <div className="pb-2">
        <div className="font-medium">{week.title}</div>
        <div className="text-sm text-muted-foreground">{week.description}</div>
      </div>

      <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
        {week.images.map((src, index) => (
          <img
            onClick={() => handleClickItem(index)}
            key={`${week.title}_${index}`}
            src={src}
            alt={`Highlight`}
            className="aspect-[7/12] w-full rounded-md object-cover hover:cursor-pointer md:hover:-translate-y-[2px]"
          />
        ))}
      </div>

      <div className="w-full">
        <Button
          variant={"orange"}
          className="w-full"
          onClick={() => handleClickItem(week.images.length)}
        >
          Video Highlights <PlayCircle className="ml-2 text-sm" size={"1rem"} />
        </Button>
      </div>

      <Dialog onOpenChange={() => setCarouselOpen(false)} open={carouselOpen}>
        <DialogContent className=" noselect md:px-16 md:py-16">
          <div className="w-full">
            <DialogHeader>
              <DialogTitle>{week.title} Highlights</DialogTitle>
              <DialogDescription>{mannyQuote}</DialogDescription>
            </DialogHeader>
            <Carousel
              opts={{ loop: true, startIndex: initialIndex }}
              className="mt-4"
            >
              <CarouselContent className="py-2">
                {week.images.map((img, index) => (
                  <CarouselItem key={index} className="h-full basis-10/12">
                    <div
                      className="rounded-md border-2"
                      key={`${week.title}_${index}_carousel`}
                    >
                      <img
                        src={img}
                        className="aspect-[0.565] w-full rounded-md object-cover"
                        alt={`Week_${index}`}
                      />
                    </div>
                  </CarouselItem>
                ))}
                <CarouselItem
                  key={week.images.length}
                  className="h-full basis-10/12"
                >
                  <div className="rounded-md border-2 border-yellow-500 shadow-center shadow-yellow-200">
                    <video
                      src={week.video}
                      className="h-full w-full rounded-md object-contain"
                      controls
                    />
                  </div>
                </CarouselItem>
              </CarouselContent>
              <CarouselPrevious />
              <CarouselNext />
            </Carousel>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
