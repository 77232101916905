import { useContext, useEffect } from "react";
import "@/style/index.css";
import { Route, Routes, Router, useNavigate } from "react-router-dom";
import Home from "./pages/home";
import { GradientOutlineButton } from "./components/ui/button";
import Schedule from "./pages/schedule/page";
import Rankings from "./pages/rankings/page";
import HallOfFame from "./pages/hall-of-fame/page";

export const bertHead = require("@/assets/berthead.png");
export const bertThumbs = require("@/assets/bertthumbs.png");
export const bertSack = require("@/assets/bertsack.png");
export const bertBaseball = require("@/assets/bertbaseball.png");
export const petanqueBoi = require("@/assets/kisspng-masters-de-ptanque-boulodrome-competitive-examin-5b14ab34d77f06.8913147815280812048827.png");
export const tomImg = require("@/assets/tom.png");
export const robImg = require("@/assets/rob.png");
export const dadImg = require("@/assets/dad.png");

export const PLAYERS = {
  Tomasz: {
    image: tomImg,
    catchphrase:
      "The kitchen was open today, so there was only ever one outcome!",
    nickname: "",
  },
  Robbie: {
    image: robImg,
    catchphrase:
      "This really is all that he lives for - he will be completely ecstatic!",
  },
  Paul: {
    image: dadImg,
    catchphrase: "There's no doubt, he will be absolutely over the moon!",
  },
} as const;

type Position = "top" | "bottom" | "left" | "right";
type Location = [Position, Position];

const SpinningBert = ({
  location,
  reverse,
  className,
}: {
  location: Location;
  reverse?: boolean;
  className?: string;
}) => {
  const navigate = useNavigate();
  return (
    <div
      className={`absolute md:${location[0]}-10 md:${location[1]}-10 ${location[0]}-5 ${location[1]}-5 group h-12 w-12 transition-all duration-300 hover:scale-125 hover:cursor-pointer md:h-20 md:w-20 ${className} pointer-events-auto`}
      onClick={() => navigate("")}
    >
      <div
        className="rotating running group-hover:paused"
        style={{ animationDirection: !reverse ? "reverse" : "normal" }}
      >
        <img
          src={bertHead}
          alt="pertanque"
          className="rotating-md"
          style={{ animationDirection: reverse ? "reverse" : "normal" }}
        />
      </div>
    </div>
  );
};

function App() {
  const navigate = useNavigate();

  const preloadImages = (imageArray: any[]) => {
    imageArray.forEach((src) => {
      const img = new Image();
      img.src = src;
    });
  };

  useEffect(() => {
    // Preload images
    preloadImages([tomImg, robImg, dadImg]);
  }, []);

  return (
    <div className="fade-in">
      <div className="pointer-events-none fixed left-0 top-0 z-20 h-screen w-screen overflow-hidden">
        <div className="absolute left-0 top-0 h-screen w-screen">
          <SpinningBert
            location={["top", "left"]}
            reverse
            className="hidden md:block"
          />
          <SpinningBert
            location={["top", "right"]}
            className="hidden md:block"
          />
          <SpinningBert location={["bottom", "left"]} />
          <SpinningBert location={["bottom", "right"]} reverse />
        </div>
      </div>

      <div className="px-5 pb-20 pt-24 md:px-24 md:pt-32 xl:px-44">
        <Routes>
          <Route path="" element={<Home />} />
          <Route path="schedule" element={<Schedule />} />
          <Route path="rankings" element={<Rankings />} />
          <Route path="hall-of-fame" element={<HallOfFame />} />
        </Routes>
      </div>

      <div
        className="noselect absolute top-3 z-10 w-full px-3 md:top-5 md:px-0"
        key="nav"
      >
        <div className="mx-auto grid max-w-2xl grid-cols-3 gap-4">
          <div className="group relative flex justify-center">
            <div className="absolute -z-10 h-20 w-20 translate-y-4 opacity-0 transition-all duration-300 group-hover:translate-y-16 group-hover:opacity-100">
              <img
                src={bertSack}
                alt="pertanque"
                className="hidden animate-bounce md:block"
              />
            </div>
            <GradientOutlineButton
              className="w-full from-primary to-graph/70"
              onClick={() => navigate("schedule")}
            >
              Schedule
            </GradientOutlineButton>
          </div>
          <div className="group relative flex justify-center">
            <div className="absolute -z-10 h-16 w-16 translate-y-4 opacity-0 transition-all duration-300 group-hover:translate-y-14 group-hover:opacity-100">
              <img
                src={bertThumbs}
                alt="pertanque"
                className="scale-pulse hidden md:block"
              />
            </div>
            <GradientOutlineButton
              className="w-full from-orange-400 to-orange-700"
              onClick={() => navigate("rankings")}
            >
              Rankings
            </GradientOutlineButton>
          </div>
          <div className="group relative flex justify-center">
            <div className="absolute -z-10 h-16 w-16 translate-y-4 opacity-0 transition-all duration-300 group-hover:translate-y-14 group-hover:opacity-100">
              <img
                src={bertBaseball}
                alt="pertanque"
                className="tilter hidden md:block"
              />
            </div>
            <GradientOutlineButton
              className="w-full from-purple-700 to-blue-700"
              onClick={() => navigate("hall-of-fame")}
            >
              Hall of Fame
            </GradientOutlineButton>
          </div>
          <div className="text-center w-full absolute top-full left-0 md:top-auto md:bottom-full">
            <div className="leading-4 md:mt-0 md:mb-[2px] text-[0.6rem] mt-1 text-muted-foreground/50">Pre-Season</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
