export const weekHighlights: {
  title: string;
  description: string;
  images: [any, any, any, any];
  video: any;
}[] = [
  {
    title: "Week 1",
    description: "What a cracker of a first week! Robbie off to a flying start! It looks like we're in for months of pure excitement!",
    images: [
      require("@/assets/hall-of-fame/week_1/dad.png"),
      require("@/assets/hall-of-fame/week_1/tub.png"),
      require("@/assets/hall-of-fame/week_1/bob.png"),
      require("@/assets/hall-of-fame/week_1/winner.png"),
    ],
    video: require("@/assets/hall-of-fame/week_1/week_1_highlights.mp4"),
  },
  {
    title: "Week 2",
    description: "It was halloween week and it did not disappoint! Ghosts and demons were lurking, but more importantly, the kitchen indeed was open! But will it help Tomasz pull through? Special guest Marcela and pomeranian Archie.",
    images: [
      require("@/assets/hall-of-fame/week_2/gud.png"),
      require("@/assets/hall-of-fame/week_2/tub.png"),
      require("@/assets/hall-of-fame/week_2/bob.png"),
      require("@/assets/hall-of-fame/week_2/marcela.png"),
    ],
    video: require("@/assets/hall-of-fame/week_2/week_2_highlights.mp4"),
  },
  {
    title: "Week 3",
    description: "Will Dad become the first Fanny Kisser in Bertanque history?! Special episode feat. \"Bertanque\" Bryony.",
    images: [
      require("@/assets/hall-of-fame/week_3/bob.png"),
      require("@/assets/hall-of-fame/week_3/dad.png"),
      require("@/assets/hall-of-fame/week_3/tub.png"),
      require("@/assets/hall-of-fame/week_3/bry.png"),
    ],
    video: require("@/assets/hall-of-fame/week_3/week_3_highlights.mp4"),
  },
  {
    title: "Week 4",
    description: "Will Tomasz finally get his first victory over Paul? And will Robbie solidify his lead or will the others make a statement?!",
    images: [
      require("@/assets/hall-of-fame/week_4/dad.png"),
      require("@/assets/hall-of-fame/week_4/tub1.png"),
      require("@/assets/hall-of-fame/week_4/tub2.png"),
      require("@/assets/hall-of-fame/week_4/bob.png"),
    ],
    video: require("@/assets/hall-of-fame/week_4/week_4_highlights.mp4"),
  },
];
